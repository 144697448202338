import { createSlice } from '@reduxjs/toolkit'

export const defaultSlice = createSlice({
    name: 'default',
    initialState: {
        name: "",
        version: 0,
        languages: [],
        registers: [],
        provinces: [],
        months: [],
        genders: [],
        maritalStatus: [],
        dictionary: {},
        copyright: `Donexa © ${new Date().getFullYear()}`,
    },
    reducers: {
        continueSession: (state, action) => {
            const { name, version, languages, registers, provinces, dictionary, months, genders, maritalStatus } = action.payload;
            state.name = name;
            state.version = version;
            state.languages = languages;
            state.registers = registers;
            state.provinces = provinces;
            state.dictionary = dictionary;
            state.months = months;
            state.genders = genders;
            state.maritalStatus = maritalStatus;
        },
        copyWith: (state, action) => {
            console.log(action.payload);
            state.name = action.payload.name ?? state.name;
            state.version = action.payload.version ?? state.version;
            state.languages = action.payload.languages ?? state.languages;
            state.registers = action.payload.registers ?? state.registers;
            state.provinces = action.payload.provinces ?? state.provinces;
            state.months = action.payload.months ?? state.months;
            state.genders = action.payload.genders ?? state.genders;
            state.maritalStatus = action.payload.maritalStatus ?? state.maritalStatus;
            state.dictionary = action.payload.dictionary ?? state.dictionary;
        },
    },
})

export const { copyWith, continueSession } = defaultSlice.actions;


export default defaultSlice.reducer;