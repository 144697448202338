/* eslint-disable react-hooks/exhaustive-deps */
import React, { Component } from 'react'
import { useSelector } from 'react-redux';
import { getNewItemWithId } from '../utils/utils';

export default class OcheInputs extends Component {

    state = {
        inputs: this.props?.reg?.inputs ?? [],
    }

    componentDidUpdate(prevPros, prevState) {
        if (prevState !== this.state) {
            const { onInputsChange } = this.props;
            const { inputs } = this.state;
            if (onInputsChange) {
                onInputsChange(inputs);
            }
        }
    }

    render() {
        const { inputs } = this.state;
        const { reg } = this.props;

        // console.log(inputs);
        return reg ? (
            <div className='oche-inputs'>
                <div className='oche-inputs-title'>
                    <p className='p-medium'>Form's inputs ({inputs.length})</p>
                    <button onClick={(ev) => {
                        ev.stopPropagation();
                        this.setState({ inputs: [...inputs, getNewItemWithId({ currentList: inputs })] })
                    }}>Add</button>
                </div>
                <div className='divider' />
                {inputs.map((e, i) => {
                    return <div className='oche-inputs-item' key={e.id}>
                        <p>{i + 1}.</p>
                        <SingleInput item={e} onChangeF={(input) => {
                            console.log("Coming input", input)
                            this.setState({
                                inputs: inputs.map((eachInput) => {
                                    return eachInput.id !== input.id ? eachInput : { ...eachInput, ...input }
                                })
                            })
                        }} />
                        <i className='modal-header fa fa-xmark' onClick={(ev) => {
                            ev.stopPropagation();
                            this.setState({ inputs: inputs.filter((element) => element.id !== e.id) });
                        }}></i>
                    </div>
                })}
            </div>
        ) : <></>
    }
}


function SingleInput({ item = {}, onChangeF = () => { } }) {

    const { languages } = useSelector(state => state.default);

    return <div className='single-oche-input'>
        <div className='input-labeled'>
            <label>Input Label</label>
            {[...languages].map((lng, i) => {
                const iName = `inputLabel_${lng.code}`;
                return <input
                    key={i}
                    name={iName}
                    placeholder={`Input label (${lng.name})`}
                    type={'name'}
                    value={item[`inputLabel_${lng.code}`]}
                    onChange={(ev) => {
                        ev.stopPropagation();
                        onChangeF({ ...item, [iName]: ev.target.value });
                    }}
                />
            })}
        </div>
        <div className='oche-languages-form'>
            <div className='input-labeled'>
                <label>Key</label>
                <input name='key' type='text' placeholder='Input Key' value={item[`key`]} onChange={(ev) => {
                    ev.stopPropagation();
                    onChangeF({ ...item, 'key': ev.target.value });
                }} />
            </div>
            <div className='input-labeled'>
                <label>Type</label>
                <select name='type' value={item[`type`]} onChange={(ev) => {
                    ev.stopPropagation();
                    onChangeF({ ...item, 'type': ev.target.value });
                }}>
                    <option value={null}>Type</option>
                    <option value="text">Text</option>
                    <option value="number">Number</option>
                    <option value="number-count">Number Count</option>
                    <option value="bool">Boolean</option>
                    <option value="select">Select</option>
                    <option value="multi-select">Multi Select</option>
                    <option value="multi-input">Multi Input</option>
                </select>
            </div>
        </div>
        <div className='input-labeled'>
            <label>Is required</label>
            <select name='isRequired' value={item[`isRequired`]} onChange={(ev) => {
                ev.stopPropagation();
                onChangeF({ ...item, 'isRequired': ev.target.value });
            }}>
                <option value={null}>Choose</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
            </select>
        </div>
        <OptionsInput dOptions={item?.options ?? []} onChangeOf={(ots) => {
            onChangeF({ ...item, options: ots });
        }} />
        <Inputs4Input dInputs={item?.inputs ?? []} onChangeOf={(ots) => {
            onChangeF({ ...item, inputs: ots });
        }} />
    </div>
}

function OptionsInput({ onChangeOf = () => { }, dOptions = [] }) {
    return <div className='options-reg'>
        <div className='oche-title'>
            <label>Options</label>
            <button onClick={(ev) => {
                ev.preventDefault();
                onChangeOf([...dOptions, getNewItemWithId({ currentList: dOptions })]);
            }}>Add</button>
        </div>
        {dOptions.map((optionFor, i) => {
            return <div key={i} className='options-inputs-row'>
                <p>{i + 1}.</p>
                <SingleOption item={optionFor} onChangeWith={(newOptionObject) => {
                    onChangeOf([...dOptions.map((element) => {
                        return element.id !== optionFor.id ? element : { ...element, ...newOptionObject }
                    })])
                }} />
                <i className='modal-header fa fa-xmark' onClick={(ev) => {
                    ev.stopPropagation();
                    onChangeOf([...dOptions.filter((element) => element.id !== optionFor.id)]);
                }}></i>
            </div>
        })}
    </div>
}

function SingleOption({ item = {}, onChangeWith = () => { } }) {

    const { languages } = useSelector(state => state.default);

    const handleChange = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const { name, value } = event.target;
        onChangeWith({ ...item, [name]: value })
    }

    return <form className='opts-single-form' onChange={handleChange}>
        <div className='input-labeled'>
            <label>Option Label</label>
            {[...languages].map((lng, i) => {
                return <input
                    key={i}
                    name={`optionLabel_${lng.code}`}
                    placeholder={`Option label (${lng.name})`}
                    type={'name'}
                    value={item[`optionLabel_${lng.code}`]}
                />
            })}
        </div>
        <div className='input-labeled'>
            <label>Value</label>
            <input type='name' name='value' placeholder='Value' value={item.value} />
        </div>
    </form>
}


function Inputs4Input({ onChangeOf = () => { }, dInputs = [] }) {
    return <div className='oche-inputsr for-inputs'>
        <div className='oche-inputs-title'>
            <p className='p-medium'>Additional Inputs ({dInputs.length})</p>
            <button onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                onChangeOf([...dInputs, getNewItemWithId({ currentList: dInputs })]);
            }}>Add</button>
        </div>
        <div className='divider' />
        {dInputs.map((e, i) => {
            return <div className='oche-inputs-item' key={e.id}>
                <p>{i + 1}.</p>
                <SingleInput item={e} onChangeF={(input) => {
                    onChangeOf([...dInputs.map((element) => {
                        return element.id !== e.id ? element : { ...element, ...input }
                    })])
                }} />
                <i className='modal-header fa fa-xmark' onClick={(ev) => {
                    ev.stopPropagation();
                    onChangeOf([...dInputs.filter((element) => element.id !== e.id)]);
                }}></i>
            </div>
        })}
    </div>
}

