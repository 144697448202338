import React, { Component } from 'react'
import './styles/modal.css';

export default class Modal extends Component {
    render() {
        const { child, onClose } = this.props;
        return (
            <div className='modal-curtain'>
                <div className='oche-modal'>
                    <div className='modal-header'>
                        <i className='fa fa-xmark' onClick={(ev) => {
                            ev.stopPropagation();
                            onClose();
                        }}></i>
                    </div>
                    <div className='divider' />
                    {child}
                </div>
            </div>
        )
    }
}
