/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { getNewItemWithId } from '../utils/utils';

export default function OcheSimpleUtils({ list = [], title = '', onLChange = () => { } }) {

    const [cList, setCList] = useState([]);

    useEffect(() => {
        setCList(list);
    }, [list]);

    const { languages } = useSelector(state => state.default);

    return (
        <div className='oche-simple-utils'>
            <div className='oche-title'>
                <p className='p-medium'>{title}({cList.length})</p>
                <button onClick={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    const newL = [...cList, getNewItemWithId({ currentList: cList })];
                    setCList(newL);
                    onLChange(newL);
                }}>Add</button>
            </div>
            <div className='divider' />
            {cList.map((item, i) => {
                const handleChange = (event) => {
                    event.stopPropagation();
                    const { name, value } = event.target;
                    const newL = cList.map((element) => element.id !== item.id ? element : { ...element, [name]: value });
                    setCList(newL);
                    onLChange(newL);
                }
                return <div key={item.id} className='options-inputs-row'>
                    <p>{i + 1}.</p>
                    <form className='oche-simple-utils-inputs' onChange={handleChange}>
                        {languages.map((e, i) => {
                            return <div key={e.id} className='input-labeled'>
                                <label>{e.name}</label>
                                <input name={e.code} type='text' placeholder={`In ${e.name}`} value={item[`${e.code}`]} />
                            </div>
                        })}
                    </form>
                    <i className='modal-header fa fa-xmark' onClick={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        const newL = cList.filter((element) => element.id !== item.id);
                        setCList(newL);
                        onLChange(newL);
                    }}></i>
                </div>
            })}
        </div>
    )
}
