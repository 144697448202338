import React, { Component } from 'react'
import { connect } from 'react-redux';
import OcheProvinces from '../components/oche_provinces';
import { copyWith } from '../logic/slice';
import { SingleSectionPart } from './oche_canevas';
import './styles/oche_country.css';

class OcheCountry extends Component {
    render() {
        const { countryName, docVersion, languages, provinces, dispatch } = this.props;
        return (
            <div className='oche-country'>
                <div className='oche-country-editing'>
                    <div className='input-labeled'>
                        <label>Country</label>
                        <input type='name' placeholder='Name of the country' value={countryName} onChange={(ev) => {
                            dispatch(copyWith({ name: ev.target.value }))
                        }} />
                    </div>
                    <div className='input-labeled'>
                        <label>Document Version</label>
                        <input type='number' placeholder='Version Number' value={docVersion} onChange={(ev) => {
                            dispatch(copyWith({ version: ev.target.value }))
                        }} />
                    </div>
                    <SingleSectionPart title='Supported Languages' data={<OcheLanguages />} />
                    <SingleSectionPart title='Entities' data={<OcheProvinces />} />
                </div>
                <div className='oche-country-overview'>
                    <h1 className='large-title'>{countryName} v{docVersion}</h1>
                    <p className='large-subtitle'>Supported Languages ({languages.length})</p>
                    {languages.map((e, i) => {
                        return <p key={i}>{i + 1}. {e.name} - {e.code}</p>
                    })}
                    <p className='large-subtitle'>Provinces ({provinces.length})</p>
                    {provinces.map((e, i) => {
                        const { municipalities, name, id } = e;
                        return <p key={id}>{i + 1}. {name} ({municipalities?.map((e, i) => i === municipalities.length - 1 ? e.name : `${e.name}, `)})</p>
                    })}
                </div>
            </div>
        )
    }
}


const mapStateToProps = (_) => ({
    countryName: _.default.name,
    docVersion: _.default.version,
    languages: _.default.languages,
    provinces: _.default.provinces,
    genders: _.default.genders,
    months: _.default.months,
    maritalStatus: _.default.maritalStatus,
});

export default connect(mapStateToProps)(OcheCountry);


class _OcheLanguages extends Component {



    render() {
        const { languages, dispatch } = this.props;
        return (
            <div className='input-labeled'>
                <div className='oche-title'>
                    <label>Supported Languages ({languages.length})</label>
                    <button onClick={(ev) => {
                        ev.stopPropagation();
                        dispatch(copyWith({
                            languages: [...languages, { id: languages.length + 1 }]
                        }));
                    }}>Add</button>
                </div>
                {languages.map((e, i) => {
                    return <div key={i} className='oche-languages-form'>
                        <div className='input-labeled'>
                            <label>Name</label>
                            <input type='name' placeholder='Name of the language' value={e.name} required onChange={(evnt) => {
                                dispatch(copyWith({
                                    languages: [...languages].map((el) => {
                                        return el.id === e.id ? { ...el, 'name': evnt.target.value } : el;
                                    })
                                }));
                            }} />
                        </div>
                        <div className='input-labeled'>
                            <label>Code</label>
                            <input type='name' placeholder='Code of the language' value={e.code} required onChange={(evnt) => {
                                dispatch(copyWith({
                                    languages: [...languages].map((el) => {
                                        return el.id === e.id ? { ...el, 'code': evnt.target.value } : el;
                                    })
                                }));
                            }} />
                        </div>
                        <i className='modal-header fa fa-xmark' onClick={(ev) => {
                            ev.stopPropagation();
                            dispatch(copyWith({ languages: [...languages].filter((el) => el.id !== e.id) }));
                        }}></i>
                    </div>
                })}
            </div>
        )
    }
}

const mapStateToPropsL = (_) => ({
    languages: _.default.languages,
});

const OcheLanguages = connect(mapStateToPropsL)(_OcheLanguages);



