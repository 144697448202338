import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './logic/store';
import OcheMain from './pages/oche_main';
import OcheHome from './pages/oche_home';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <Routes>
          <Route path='/' element={<OcheMain />} />
          <Route path='/session' element={<OcheHome />} />
          <Route path='*' element={<div>Not found</div>} />
        </Routes>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

if (process.env.NODE_ENV === 'production') {
  console.log = () => { };
  console.error = () => { };
  console.debug = () => { };
}





