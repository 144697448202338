import React, { Component, useState } from 'react'
import { connect, useSelector } from 'react-redux';
import { copyWith } from '../logic/slice';
import { getNewItemWithId } from '../utils/utils';
import { ViewRegisters } from './oche_registers'
import './styles/oche_canevas.css';

class OcheCaneva extends Component {

    state = {
        register: null,
    }


    usSection({ dSections = () => { return [] } }) {
        const { dispatch, registers } = this.props;
        const { register } = this.state;
        dispatch(copyWith({
            registers: [...registers].map((e) => {
                return e.id !== register?.id ? e : {
                    ...register,
                    sections: dSections([]),
                }
            })
        }));
    }

    render() {
        const { register } = this.state;
        const { registers } = this.props;
        const currentReg = registers.filter((element) => element.id === register?.id);
        const sections = currentReg.length ? currentReg[0].sections ?? [] : [];
        return (
            <div className='oche-canevas'>
                <ViewRegisters canDelete={false} onTap={(reg) => {
                    this.setState({ register: { ...reg } });
                }} />
                {register ? <div className='caneva-builder'>
                    <div className='oche-inputs-title'>
                        <p className='p-medium'>{register?.code} ({sections.length})</p>
                        <button onClick={(ev) => {
                            ev.stopPropagation();
                            this.usSection({
                                dSections: (_) => {
                                    return [...sections, getNewItemWithId({ currentList: sections })];
                                }
                            })
                        }}>Add</button>
                    </div>
                    <div className='divider' />
                    {sections.map((e, i) => {
                        return <div className='oche-inputs-item' key={e.id}>
                            <p>{i + 1}.</p>
                            <SingleSection key={i} section={e} onChange={(sec) => {
                                console.log("Section has changed", { ...e, ...sec });
                                this.usSection({
                                    dSections: (_) => {
                                        return sections.map((secS) => {
                                            return secS.id !== sec?.id ? secS : { ...secS, ...sec }
                                        })
                                    }
                                })
                            }} />
                            <i className='modal-header fa fa-xmark' onClick={(ev) => {
                                ev.stopPropagation();
                                ev.preventDefault();
                                this.usSection({
                                    dSections: (_) => {
                                        return sections.filter((secS) => secS.id !== e?.id)
                                    }
                                })
                            }}></i>
                        </div>
                    })}
                </div> : <></>}
            </div>
        )
    }
}

const mapStateToPropsL = (_) => ({
    registers: _.default.registers,
});

export default connect(mapStateToPropsL)(OcheCaneva);

function SingleSection({ section = {}, onChange = () => { } }) {

    const { languages } = useSelector(state => state.default);

    const { dependOnSelect } = section;

    const handleChange = (event) => {
        const { name, value } = event.target;
        onChange({ ...section, [name]: value });
    }

    return <div className='single-section'>
        <SingleSectionPart title='Indicators' data={<form className='indicator-form' onChange={handleChange}>
            <p>Title</p>
            {languages.map((e, i) => {
                return <div key={e.id} className='input-labeled'>
                    <input
                        name={`title_${e.code}`}
                        type='text'
                        placeholder={`${e.name}`}
                        value={section[`title_${e.code}`]}
                    />
                </div>
            })}
            <div className='input-labeled'>
                <label>Does it depend on select's options ?</label>
                <select name='dependOnSelect' value={section[`dependOnSelect`]}>
                    <option value={null}>Choose</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                </select>
            </div>
            {dependOnSelect === 'true' ? <div className='input-labeled'>
                <label>Given select key (Key must match the one used on register form) ?</label>
                <input
                    name='selectKey'
                    type='text'
                    placeholder="Select key"
                    value={section[`selectKey`]} />
            </div> : dependOnSelect === 'false' ? <Indicators indicats={section?.indicators ?? []} onChangeOf={(ots) => {
                onChange({ ...section, indicators: ots });
            }} /> : <></>}
        </form>} />
        <SingleSectionPart title='Stat inputs' data={<StatInputs onChangeOf={(stIpts) => {
            onChange({ ...section, statInputs: stIpts });
        }} statInputs={section?.statInputs ?? []} />} />
    </div>
}

export function SingleSectionPart({ title = '', data = <div /> }) {
    const [collapsed, setCollapsed] = useState(false);
    return <div className='s-action-main'>
        <div className='s-action-part'>
            <p>{title}</p>
            <i className={`fa fa-arrow-${collapsed ? 'up' : 'down'}`} onClick={(ev) => {
                ev.stopPropagation();
                setCollapsed(!collapsed);
            }} />
        </div>
        {collapsed && <div className='s-action-data'>{data}</div>}
    </div>
}

function Indicators({ onChangeOf = () => { }, indicats = [] }) {
    return <div className='options-reg'>
        <div className='oche-title'>
            <label>Indicators</label>
            <button onClick={(ev) => {
                ev.preventDefault();
                onChangeOf([...indicats, getNewItemWithId({ currentList: indicats })]);
            }}>Add</button>
        </div>
        {indicats.map((optionFor, i) => {
            return <div key={i} className='options-inputs-row'>
                <p>{i + 1}.</p>
                <SingleIndicator item={optionFor} onChangeWith={(newOptionObject) => {
                    onChangeOf([...indicats.map((element) => {
                        return element.id !== optionFor.id ? element : { ...element, ...newOptionObject }
                    })])
                }} />
                <i className='modal-header fa fa-xmark' onClick={(ev) => {
                    ev.stopPropagation();
                    onChangeOf([...indicats.filter((element) => element.id !== optionFor.id)]);
                }}></i>
            </div>
        })}
    </div>
}

function SingleIndicator({ item = {}, onChangeWith = () => { } }) {

    const { languages } = useSelector(state => state.default);

    const handleChange = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const { name, value } = event.target;
        onChangeWith({ ...item, [name]: value })
    }

    return <form className='opts-single-form opts-single-section' onChange={handleChange}>
        <div className='input-labeled'>
            <label>Label</label>
            {[...languages].map((lng, i) => {
                return <input
                    key={i}
                    name={`indicator_${lng.code}`}
                    placeholder={`Option label (${lng.name})`}
                    type={'name'}
                    value={item[`indicator_${lng.code}`]}
                />
            })}
        </div>
        <div className='input-labeled'>
            <label>Value</label>
            <input type='name' name='value' placeholder='Value' value={item.value} />
        </div>
    </form>
}

function StatInputs({ onChangeOf = () => { }, statInputs = [] }) {
    return <div className='options-reg'>
        <div className='oche-title'>
            <label>Inputs ({statInputs.length})</label>
            <button onClick={(ev) => {
                ev.preventDefault();
                onChangeOf([...statInputs, getNewItemWithId({ currentList: statInputs })]);
            }}>Add</button>
        </div>
        {statInputs.map((optionFor, i) => {
            return <div key={i} className='options-inputs-row'>
                <p>{i + 1}.</p>
                <SingleStatInput item={optionFor} onChangeWith={(newOptionObject) => {
                    onChangeOf([...statInputs.map((element) => {
                        return element.id !== optionFor.id ? element : { ...element, ...newOptionObject }
                    })])
                }} />
                <i className='modal-header fa fa-xmark' onClick={(ev) => {
                    ev.stopPropagation();
                    onChangeOf([...statInputs.filter((element) => element.id !== optionFor.id)]);
                }}></i>
            </div>
        })}
    </div>
}

function SingleStatInput({ item = {}, onChangeWith = () => { } }) {
    return <div className='opts-single-form opts-single-section'>
        <div className='input-labeled'>
            <label>Type</label>
            <select name='type' value={item.type} onChange={(event) => {
                event.preventDefault();
                event.stopPropagation();
                const { name, value } = event.target;
                onChangeWith({ ...item, [name]: value, data: {} });
            }}>
                <option value={""}>Choose</option>
                <option value='simple-count'>Simple Count</option>
                <option value='age/gender'>Genders under age logic</option>
                <option value='question/gender'>Genders under question</option>
                <option value="total/gender">Genders under Total</option>
                <option value='question'>Question based</option>
                <option value='options'>Select Options based</option>
            </select>
        </div>
        {item?.type ? <div className='input-labeled'>
            <StInput type={item.type} initValue={item.data} onChangeR={(newData) => {
                onChangeWith({ ...item, data: newData });
            }} />
        </div> : <></>}
    </div>
}


function StInput({ type = '', initValue = {}, onChangeR = () => { } }) {

    const { languages } = useSelector(state => state.default);

    const handleChange = (event) => {
        const { name, value } = event.target;
        onChangeR({ ...initValue, [name]: value });
    }

    if (type === "age/gender") {
        return <form onChange={handleChange}>
            <div className='oche-languages-form'>
                <div className='input-labeled'>
                    <label>Min age in days</label>
                    <input
                        name='min-age'
                        type='number'
                        placeholder="Min age (in days)"
                        value={initValue[`min-age`]} />
                </div>
                <div className='input-labeled'>
                    <label>Max age in days</label>
                    <input
                        name='max-age'
                        type='number'
                        placeholder="Max age (in days)"
                        value={initValue[`max-age`]} />
                </div>
            </div>
            <div className='input-labeled'>
                <label>Display unit</label>
                <select name='unit' value={initValue[`unit`]}>
                    <option value={null}>Choose</option>
                    <option value="jours">Days</option>
                    <option value="mois">Month</option>
                    <option value="ans">Years</option>
                </select>
            </div>
        </form>;
    } else if (type === "question/gender" || type === "question") {

        return <form onChange={handleChange}>
            <div className='input-labeled'>
                <label>Label</label>
                {[...languages].map((lng, i) => {
                    const iName = `label_${lng.code}`;
                    return <input
                        key={i}
                        name={iName}
                        placeholder={`Label (${lng.name})`}
                        type={'name'}
                        value={initValue[`label_${lng.code}`]}
                    />
                })}
            </div>
            <div className='input-labeled'>
                <label>Question Key</label>
                <input
                    name='key'
                    type='text'
                    placeholder="Question Key"
                    value={initValue[`key`]} />
            </div>
        </form>
    } else if (type === "options") {
        return <form onChange={handleChange}>
            <div className='input-labeled'>
                <label>Label</label>
                {[...languages].map((lng, i) => {
                    const iName = `label_${lng.code}`;
                    return <input
                        key={i}
                        name={iName}
                        placeholder={`Label (${lng.name})`}
                        type={'name'}
                        value={initValue[`label_${lng.code}`]}
                    />
                })}
            </div>
            <div className='input-labeled'>
                <label>Select Key</label>
                <input
                    name='key'
                    type='text'
                    placeholder="Select Key"
                    value={initValue[`key`]} />
            </div>
        </form>
    } else {
        return <></>;
    }
}


