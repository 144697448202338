import React, { Component, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { copyWith } from '../logic/slice';
import './styles/oche_registers.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { getNewItemWithId } from '../utils/utils';

class OcheRegisters extends Component {


    state = {
        formValues: {},
    }

    handleSubmit = (event) => {
        const { registers, dispatch } = this.props;
        const formV = this.state.formValues;
        const isUpdate = formV?.id ? true : false;
        event.preventDefault();
        console.log(this.state.formValues);
        if (isUpdate) {
            dispatch(copyWith({
                registers: [...registers].map((e) => e.id !== formV.id ? e : { ...e, ...formV })
            }));
        } else {
            dispatch(copyWith({
                registers: [...registers, { ...getNewItemWithId({ currentList: registers }), ...this.state.formValues, inputs: [] }]
            }));
        }
        window.document.querySelector('#reg-form')?.reset();
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        this.setState({ formValues: { ...this.state.formValues, [name]: value } });
    }

    render() {
        const { languages } = this.props;
        const { formValues } = this.state;
        const isUpdate = formValues?.id ? true : false;
        return (
            <div className='oche-registers'>
                <ViewRegisters canDelete={true} onTap={(reg) => {
                    this.setState({ formValues: { ...reg } });
                }} />
                <form id='reg-form' className='oche-registers-form' onChange={this.handleChange} onSubmit={this.handleSubmit}>
                    <p className='p-medium'>{isUpdate ? 'Update' : 'Create'} register</p>
                    <div className='divider' />
                    {this._inputs.map((e, i) => {
                        return e.multiLang === false ? <div className='input-labeled'>
                            <label>{e.name}</label>
                            {this._getInputFor(e, formValues)}
                        </div> : <div className='input-labeled'>
                            <label>{e.name}</label>
                            {languages.map((lng, i) => {
                                return this._getInputFor({ ...e, name: `${e.name}  in ${lng.name}`, ipName: `${e.ipName}_${lng.code}` }, formValues)
                            })}
                        </div>

                    })}
                    <button>Add</button>
                </form>
            </div>
        )
    }

    _inputs = [
        { name: "Name", multiLang: true, type: 'name', ipName: "name" },
        { name: "Description", multiLang: true, type: 'textarea', ipName: "description" },
        { name: "Code", multiLang: false, type: 'name', ipName: "code" },
        { name: "Does it require a patient ?", multiLang: false, type: 'select', options: [{ name: "Yes", value: true }, { name: "No", value: false },], ipName: "isLinked" },
        { name: "Patient Age Min", multiLang: false, type: 'number', ipName: "min-age" },
        { name: "Patient Age Max", multiLang: false, type: 'number', ipName: "max-age" },
        { name: "Guide", multiLang: true, type: 'richtext', ipName: "guide" },
        { name: "Tutorial", multiLang: true, type: 'url', ipName: "tutorial" },
    ];

    _getInputFor(input, defaults) {
        if (!Object.keys(input).length) return <></>
        switch (input.type) {
            case 'select':
                return <select name={input.ipName} required value={defaults[input.ipName] ?? undefined}>
                    <option value={null}>{input.name}</option>
                    {input?.options?.map((e, i) => {
                        return <option key={i} value={e.value}>{e.name}</option>;
                    })}
                </select>
            case 'richtext':
                return <RicheTextEditor
                    onChange={(el) => {
                        this.setState({ formValues: { ...this.state.formValues, [input.ipName]: el } });
                    }}
                    name={input.ipName}
                    theme="snow"
                    dFault={defaults[input.ipName] ?? undefined}
                    required
                    placeholder={input.name}
                />
            case 'textarea':
                return <textarea name={input.ipName} placeholder={input.name} required value={defaults[input.ipName] ?? undefined} />
            default:
                return <input name={input.ipName} placeholder={input.name} required value={defaults[input.ipName] ?? undefined} />
        }
    }
}


const mapStateToProps = (_) => ({
    countryName: _.default.name,
    docVersion: _.default.version,
    languages: _.default.languages,
    registers: _.default.registers,
});

export default connect(mapStateToProps)(OcheRegisters);



export function ViewRegisters({ onTap = () => { }, canDelete = false }) {

    const { registers } = useSelector(state => state.default);
    const dispatch = useDispatch();

    return <div className='oche-registers-list'>
        <p className='p-medium'>Registers ({registers.length})</p>
        <div className='divider' />
        {registers.map((e) => {
            const linkLabel = e.isLinked === "true" ? "With patient" : "Without patient";
            const linkClass = e.isLinked === "true" ? "bg-green" : "bg-orange";
            return <div key={e.id} className='single-register' onClick={(ev) => {
                ev.stopPropagation();
                onTap(e);
            }}>
                <div className='flex-row'>
                    <p className="p-medium">{e.code}</p>
                    {canDelete ? <i className='fa fa-xmark' onClick={(ev) => {
                        ev.stopPropagation();
                        dispatch(copyWith({ registers: [...registers].filter((el) => el.id !== e.id) }));
                    }}></i> : <></>}
                </div>
                <p id='p-bg' className={linkClass}>{linkLabel}</p>
            </div>
        })}
    </div>
}


function RicheTextEditor({ placeholder = "", dFault = "", onChange = () => { } }) {
    const [value, setValue] = useState(dFault);

    useEffect(() => {
        setValue(dFault);
    }, [dFault])

    return <ReactQuill
        id='react-quiller'
        theme="snow"
        value={value}
        onChange={(e) => {
            setValue(e)
            onChange(e)
        }} placeholder={placeholder} />;
}