import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OcheSimpleUtils from '../components/oche-simple-utils';
import { copyWith } from '../logic/slice';
import { apiCall } from '../providers/api';
import { exportToS3ForTesting } from '../utils/utils';
import { SingleSectionPart } from './oche_canevas';
import './styles/oche_languages.css';



export default function OcheLanguages() {

  const { dictionary, languages, genders, maritalStatus, months } = useSelector(state => state.default);
  const dispatch = useDispatch();
  const [keys, setKeys] = useState([]);
  const [loading, setLoader] = useState(true);

  useEffect(() => {
    apiCall({
      endPoint: 'pv-d/OpenCarenet-Locales-Keys.json',
      method: 'GET',
      baseUrl: 'https://dpictures.s3.amazonaws.com'
    }).then((result) => {
      console.log(result);
      setLoader(false);
      if (result.error) {
        setKeys([]);
      } else {
        setKeys(result.keys);
      }
    });
  }, [keys.length]);

  return !loading ? (
    <div className='oche-languages'>
      <div className='oche-languages-dic'>
        <div className='oche-inputs-title'>
          <p className='p-medium'>Dictionary of Words</p>
          <button onClick={(ev) => {
            ev.preventDefault();
            ev.stopPropagation();
          }}>Add</button>
        </div>
        <div className='divider' />
        {keys.map((e) => {
          return <SingleSectionPart key={e} title={e} data={<div className='input-labeled'>
            {[...languages].map((lng, i) => {
              const obj = dictionary[e] ?? {};
              return <input
                key={i}
                name={lng.code}
                placeholder={`${e} in (${lng.name})`}
                type={'text'}
                value={obj[`${lng.code}`]}
                onChange={(event) => {
                  event.stopPropagation();
                  dispatch(copyWith({ dictionary: { ...dictionary, [e]: { ...dictionary[e], [lng.code]: event.target.value } } }))
                }}
              />
            })}
          </div>} />
        })}
      </div>
      <div className='language-utils'>
        <NewLocaleKeyForm keys={keys} onNewKey={(key) => {
          setKeys([...keys, key]);
        }} />
        <SingleSectionPart title='Genders' data={<OcheSimpleUtils title='Genders' list={genders} onLChange={(newList) => {
          dispatch(copyWith({ genders: newList }))
        }} />} />
        <SingleSectionPart title='Marital Status' data={<OcheSimpleUtils title='Marital Status' list={maritalStatus} onLChange={(newList) => {
          dispatch(copyWith({ maritalStatus: newList }))
        }} />} />
        <SingleSectionPart title='Year Months' data={<OcheSimpleUtils title='Year Months' list={months} onLChange={(newList) => {
          dispatch(copyWith({ months: newList }))
        }} />} />
      </div>
    </div>
  ) : <div className='center'>Rindira gato...</div>
}



function NewLocaleKeyForm({ onNewKey = () => { }, keys = [] }) {

  const [key, setKey] = useState(null);
  const [adding, setAdding] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setAdding(true);
    const storeObj = { keys: [...keys, key] };
    const fileName = 'OpenCarenet-Locales-Keys';
    const json = JSON.stringify(storeObj, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    blob.name = fileName + ".json";
    blob.lastModified = new Date();
    exportToS3ForTesting(new File([blob], blob.name, { type: blob.type })).then((resp) => {
      setAdding(false);
      onNewKey(key);
      document?.getElementById('new-key-form-ref')?.reset();
    });

  }

  return <form id='new-key-form-ref' className='new-key-form' onSubmit={handleSubmit}>
    <div className='input-labeled'>
      <label>Add new key to dictionary</label>
      <input
        name='key'
        placeholder='key'
        type={'text'}
        required={true}
        onChange={(event) => {
          event.stopPropagation();
          setKey(event.target.value);
        }}
      />
    </div>
    <button className={adding && 'button-loading'}>{adding ? "Rindira gato..." : "Add key"}</button>
  </form>
}
